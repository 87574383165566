// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: 0d47a1, secondary: 69f0ae
$_palettes: (
  primary: (
    0: #000000,
    10: #001945,
    20: #002d6f,
    25: #0d47a1,
    // 25: #003785,
    30: #00429c,
    35: #1a4ea8,
    40: #2b5bb5,
    50: #4974d0,
    60: #648eec,
    70: #85a9ff,
    80: #b0c6ff,
    90: #d9e2ff,
    95: #eef0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #002112,
    20: #003822,
    25: #00452b,
    30: #005233,
    35: #005f3c,
    40: #006c45,
    50: #008858,
    60: #00a56c,
    70: #31c284,
    80: #69f0ae,
    // 80: #55de9e,
    90: #75fbb8,
    95: #bfffd8,
    98: #e8ffee,
    99: #f4fff5,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #2a132c,
    20: #412742,
    25: #4d324e,
    30: #593d5a,
    35: #654966,
    40: #725572,
    50: #8c6d8c,
    60: #a786a6,
    70: #c3a0c1,
    80: #e0bbdd,
    90: #fdd7fa,
    95: #ffebfb,
    98: #fff7fa,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1b1b1f,
    20: #303034,
    25: #3b3b3f,
    30: #46464a,
    35: #525256,
    40: #5e5e62,
    50: #77777a,
    60: #919094,
    70: #acabaf,
    80: #c7c6ca,
    90: #e3e2e6,
    95: #f2f0f4,
    98: #fbf8fd,
    99: #fefbff,
    100: #ffffff,
    4: #0d0e11,
    6: #121316,
    12: #1f1f23,
    17: #292a2d,
    22: #343438,
    24: #39393c,
    87: #dbd9dd,
    92: #e9e7ec,
    94: #efedf1,
    96: #f5f3f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #191b23,
    20: #2e3038,
    25: #393b43,
    30: #44464f,
    35: #50525a,
    40: #5c5e67,
    50: #757780,
    60: #8f9099,
    70: #a9abb4,
    80: #c5c6d0,
    90: #e1e2ec,
    95: #eff0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));

$primary-color: mat.get-theme-color($light-theme, primary, 25);
$primary-text: mat.get-theme-color($light-theme, 'on-primary');
$accent-color: mat.get-theme-color($light-theme, secondary, 80);
$accent-text: mat.get-theme-color($light-theme, 'on-secondary');

$banner-section-1-text: #6B6B6B;
$info-tile-background: #F5F5F5;
$info-title-text: #313131;