
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './m3-theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  // @include mat.all-component-themes(m3-theme.$light-theme);
  @include mat.core-theme(m3-theme.$light-theme);

  @include mat.sidenav-theme(m3-theme.$light-theme);

  @include mat.button-theme(m3-theme.$light-theme);

  @include mat.toolbar-theme(m3-theme.$light-theme);

  @include mat.list-theme(m3-theme.$light-theme);

}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($public-site-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($public-site-theme);
/* You can add global styles to this file, and also import other style files */

html, body, .drawer-container { height: 100%; }
body { margin: 0; padding: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.centered-text {
  text-align: center;
}

.primary-toolbar {
  --mat-toolbar-container-background-color: #{m3-theme.$primary-color};
  --mat-toolbar-container-text-color:  #{m3-theme.$primary-text};
}

.primary-color {
  color: m3-theme.$primary-color;
}

.primary-background {
  background-color: m3-theme.$primary-color;
}

.gray-toolbar {
  --mat-toolbar-container-background-color: #{m3-theme.$info-tile-background};
}

.primary-btn {
  --mdc-protected-button-container-color: #{m3-theme.$primary-color};
}

.accent-btn {
  --mdc-protected-button-container-color: #{m3-theme.$accent-color};
  --mdc-filled-button-container-color: #{m3-theme.$accent-color};
  --mdc-filled-button-label-text-color: #{m3-theme.$primary-color};
}

.accent-color {
  color: m3-theme.$accent-color;
}

.secondary-text {
  color: m3-theme.$banner-section-1-text;
}

.white-border {
  --mdc-outlined-button-outline-color: white;
  --mdc-outlined-button-label-text-color: white;
}

.sub-text {
  font-size: 24px;
  font-weight: 400;
  color: m3-theme.$primary-text;
  margin-bottom: 10px;
}

@media(max-width: 600px) {
  .sub-text {
    font-size: 16px;
  }
}